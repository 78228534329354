import { AiOutlineCalendar } from "react-icons/ai";
import { FcSearch } from "react-icons/fc";
import { FiShoppingBag } from "react-icons/fi";
import { MdCreateNewFolder } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { BsBuildingFillAdd } from "react-icons/bs";
import { MdAddchart } from "react-icons/md";
import { TfiViewListAlt } from "react-icons/tfi";
import { CiViewList } from "react-icons/ci";
import { LuBookCopy } from "react-icons/lu";

export const links = {
    sharmaRoadLines: [
        {
            title: 'Dashboard',
            links: [
                {
                    name: 'Home',
                    icon: <FiShoppingBag />,
                },
                {
                    name: 'Create Invoice',
                    icon: <MdCreateNewFolder />,
                },
                {
                    name: 'View Invoices',
                    icon: <TiDocumentText />,
                },
                {
                    name: 'Search Invoice',
                    icon: <FcSearch />,
                },
                {
                    name: 'Invoice Without Amount',
                    icon: <MdCreateNewFolder />,
                },
                {
                    name: 'Add Company',
                    icon: <BsBuildingFillAdd />,
                },
            ],
        },
        {
            title: 'Apps',
            links: [
                {
                    name: 'calendar',
                    icon: <AiOutlineCalendar />,
                }
            ],
        },
    ],
    sharmaInteriors: [
        {
            title: 'Dashboard',
            links: [
                {
                    name: 'Add Work Items',
                    icon: <MdAddchart />,
                },
                {
                    name: 'Ledger',
                    icon: <LuBookCopy />,
                },
                {
                    name: 'View Work Items',
                    icon: <CiViewList />,
                },
                {
                    name: 'Add Invoice',
                    icon: <MdCreateNewFolder />,
                },
                {
                    name: 'View Invoice',
                    icon: <TfiViewListAlt />,
                },
            ],
        }
    ]
}






