import React, { useEffect, useState } from 'react'
import { AutoComplete, BasicInput, Header, Skeleton } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import Api from '../network/proxy'
import { toast } from 'react-toastify'
import Loader from '../utils/loader'
import DataGridCompnent from '../utils/DataGrid'
import { transformGridData } from '../utils/commonFunction'

const Ledger = () => {
    const [date, setDate] = useState('')
    const [name, setName] = useState('')
    const [reason, setReason] = useState('')
    const [transactionType, setTransactionType] = useState('')
    const [amount, setAmount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState([])
    const [fetch, setFetch] = useState(0)
    const { currentColor } = useStateContext()
    const onSubmit = async () => {
        try {
            const payload = {
                date,
                name,
                reason,
                transactionType,
                amount
            }
            setLoading(true)
            const response = await Api.doPost('ledger/create', payload);
            if (response.success === true) {
                setDate('')
                setName('')
                setReason('')
                setTransactionType('')
                setAmount('')
                toast.success(response.message)
                setFetch(fetch + 1)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                return toast.error(error.response.data.message)
            }
            toast.error("Internal server error")
        }
    }
    const column = [
        { field: "id", headerName: "Sno.", width: 80 },
        {
            field: 'name',
            headerName: 'Name',
            width: '240',
            textAlign: 'Center',
        },
        {
            field: 'date',
            headerName: 'Date',
            textAlign: 'Center',
            width: '120',
        },
        {
            field: 'transactionType',
            headerName: 'Transaction Type',
            textAlign: 'Center',
            width: '160',
        },
        {
            field: 'reason',
            headerName: 'Reason',
            textAlign: 'Center',
            width: '200',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            textAlign: 'Center',
            width: '200',
        }
    ]
    useEffect(() => {
        async function getClient() {
            try {
                const res = await Api.doGet('ledger/all', {});
                if (res.success === true) {
                    const data = transformGridData(res.data);
                    setRows(data)
                }
            } catch (error) {
                if (error.response) {
                    return toast.error(error.response.data.message)
                }
                toast.error("Internal server error")
            }
        }
        getClient()
    }, [fetch])
    return (
        <Skeleton>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Ledger" />
                <div>
                    <div className='mb-5'>
                        <div className='grid md:grid-cols-5  gap-5'>
                            <BasicInput
                                id="date"
                                label={"Date"}
                                value={date}
                                type={"date"}
                                placeholder="enter date"
                                onChange={(e) => setDate(e)}
                            />
                            <BasicInput
                                id="name"
                                label={"Enter Name"}
                                value={name}
                                type={"text"}
                                placeholder="Enter name"
                                onChange={(e) => setName(e)}
                            />
                            <BasicInput
                                id="reason"
                                label={"Reason"}
                                value={reason}
                                type={"text"}
                                placeholder="Enter Address"
                                onChange={(e) => setReason(e)}
                            />
                            <AutoComplete
                                id="transactionType"
                                label={"Transaction Type"}
                                value={transactionType}
                                type={"text"}
                                placeholder="enter transaction type"
                                onChange={setTransactionType}
                                options={['Credit', 'Debit']}
                            />
                            <BasicInput
                                id="amount"
                                label={"Amount"}
                                value={amount}
                                type={"number"}
                                placeholder="enter amount"
                                onChange={(e) => setAmount(e)}
                            />
                        </div>
                    </div>
                    <div className='space-x-5 mt-10 w-full'>
                        <button
                            type="submit"
                            onClick={onSubmit}
                            style={{ backgroundColor: currentColor, color: "white", borderRadius: "5px" }}
                            className={` text-base p-3 w-1/3 hover:drop-shadow-xl hover:bg-light-gray`}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div className="m-1 md:m-10 mt-24 p-2 bg-white rounded-3xl">
                <div className='mt-20'><DataGridCompnent columns={column} rows={rows} /></div>
            </div>
            {
                loading && <Loader color={currentColor} />
            }

        </Skeleton>
    )
}

export default Ledger
