
export const transformGridData = (data) => {
    let arr = [];
    if (data && Array.isArray(data) && data.length > 0) {
        arr = data.map((el, i) => ({
            ...el,
            id: i + 1,
            date: el.date && !isNaN(new Date(el.date)) ? new Date(el.date).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }).replace(',', '') : undefined
        }))
    }
    return arr;
}