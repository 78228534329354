import React, { useEffect, useState } from 'react'
import { AutoComplete, BasicInput, Header, Skeleton } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import Api from '../network/proxy'
import { toast } from 'react-toastify'
import Loader from '../utils/loader'
import DataGridCompnent from '../utils/DataGrid'
import { transformGridData } from '../utils/commonFunction'



function AddWorkitems() {
    const [date, setDate] = useState('')
    const [doorType, setDoorType] = useState('')
    const [location, setLocation] = useState('')
    const [size, setSize] = useState('')
    const [quantity, setQuantity] = useState('')
    const [doorName, setDoorName] = useState('')
    const [loading, setLoading] = useState(false)
    const { currentColor } = useStateContext()
    const onSubmit = async () => {
        try {
            const payload = {
                date,
                doorType,
                location,
                size,
                quantity,
                doorName,
            }
            setLoading(true)
            const response = await Api.doPost('workitem/create', payload);
            if (response.success === true) {
                setDate('')
                setDoorType('')
                setLocation('')
                setSize('')
                setQuantity('')
                setDoorName('')
                toast.success(response.message)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                return toast.error(error.response.data.message)
            }
            toast.error("Internal server error")
        }
    }

    return (
        <Skeleton>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Add Work Item" />
                <div>
                    <div className='mb-5'>
                        <div className='grid md:grid-cols-4  gap-5'>
                            <BasicInput
                                id="date"
                                label={"Date"}
                                required
                                value={date}
                                type={"date"}
                                placeholder="Date"
                                onChange={(e) => setDate(e)}
                            />
                            <AutoComplete
                                id="doorType"
                                label={"Door Type"}
                                required
                                value={doorType}
                                type={"text"}
                                placeholder="enter door type"
                                onChange={setDoorType}
                                options={['Single Door', 'Double Door']}
                            />
                            <BasicInput
                                id="location"
                                label={"Location"}
                                required
                                value={location}
                                type={"text"}
                                placeholder="enter location"
                                onChange={(e) => setLocation(e)}
                            />
                            <BasicInput
                                id="size"
                                label={"Size"}
                                value={size}
                                type={"text"}
                                placeholder="enter size"
                                onChange={(e) => setSize(e)}
                            />
                            <BasicInput
                                id="quantity"
                                label={"Quantity"}
                                value={quantity}
                                type={"text"}
                                placeholder="Enter Quantity"
                                onChange={(e) => setQuantity(e)}
                            />
                            <BasicInput
                                id="doorName"
                                label={"Door Name"}
                                value={doorName}
                                type={"text"}
                                placeholder="enter door name"
                                onChange={(e) => setDoorName(e)}
                            />
                        </div>
                    </div>
                    <div className='space-x-5 mt-10 w-full'>
                        <button
                            type="submit"
                            onClick={onSubmit}
                            style={{ backgroundColor: currentColor, color: "white", borderRadius: "5px" }}
                            className={` text-base p-3 w-1/3 hover:drop-shadow-xl hover:bg-light-gray`}
                        >
                            Add Work Item
                        </button>
                    </div>
                </div>
            </div>
            {
                loading && <Loader color={currentColor} />
            }
        </Skeleton>
    )
}

export default AddWorkitems
