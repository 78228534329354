import React, { useEffect, useState } from 'react'
import { Skeleton } from '../components';
import DataGridCompnent from '../utils/DataGrid';
import Api from '../network/proxy';
import { transformGridData } from '../utils/commonFunction';
import { toast } from 'react-toastify';

const ViewInvoice = () => {
    const [rows, setRows] = useState([]);
    const [fetch, setFetch] = useState(0)
    const column = [
        { field: "id", headerName: "Sno.", width: 80 },
        {
            field: 'clientName',
            headerName: 'Name',
            width: '240',
            textAlign: 'Center',
        },
        {
            field: 'date',
            headerName: 'Date',
            textAlign: 'Center',
            width: '100',
        },
        {
            field: 'transactionType',
            headerName: 'Transaction Type',
            textAlign: 'Center',
            width: '200',
        },
        {
            field: 'reason',
            headerName: 'Reason',
            textAlign: 'Center',
            width: '200',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            textAlign: 'Center',
            width: '200',
        }
    ]
    useEffect(() => {
        async function getInvoice() {
            try {
                const res = await Api.doGet('interiors/invoice/all', {});
                if (res.success === true) {
                    const data = transformGridData(res.data)
                    setRows(data)
                }
            } catch (error) {
                if (error.response) {
                    return toast.error(error.response.data.message)
                }
                toast.error("Internal server error")
            }
        }
        getInvoice()
    }, [fetch])
    return (
        <Skeleton>
            <div className="m-1 md:m-10 mt-24 p-2 bg-white rounded-3xl">
                <div className='mt-20'><DataGridCompnent columns={column} rows={rows} /></div>
            </div>
        </Skeleton>
    )
}

export default ViewInvoice
