import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Ecommerce, Calendar, ViewBills, CreateInvoice, Login, SearchInvoice, InvoiceWithoutAmount } from './pages';
import './App.css';
import { useStateContext } from './contexts/ContextProvider';
import LoggedOutRoutes from './routes/LoggedOutRoutes';
import LoggedInRoutes from './routes/LoggedInRoutes';
import AddCompany from './pages/AddCompany';
import AddWorkitems from './pages/AddWorkitems';
import ViewWorkItems from './pages/ViewWorkItems';
import AddInvoice from './pages/AddInvoice';
import ViewInvoice from './pages/ViewInvoice';
import Ledger from './pages/Ledger';

const App = () => {
  const { setCurrentColor, setCurrentMode } = useStateContext();
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route element={<LoggedOutRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<LoggedInRoutes />}>
          <Route path="/" element={(<Ecommerce />)} />
          <Route path="/Home" element={(<Ecommerce />)} />
          <Route path="/Create Invoice" element={<CreateInvoice />} />
          <Route path="/View Invoices" element={(<ViewBills />)} />
          <Route path="/Search Invoice" element={(<SearchInvoice />)} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/invoice without amount" element={<InvoiceWithoutAmount />} />
          <Route path="/Add Work Items" element={<AddWorkitems />} />
          <Route path="/View Work Items" element={<ViewWorkItems />} />
          <Route path="/Add Invoice" element={<AddInvoice />} />
          <Route path="/View Invoice" element={<ViewInvoice />} />
          <Route path="/Ledger" element={<Ledger />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
};

export default App;
