import React from 'react'
import { Skeleton } from '../components'

const AddInvoice = () => {
    return (
        <Skeleton>

        </Skeleton>
    )
}

export default AddInvoice
