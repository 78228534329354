import React from 'react';
import { Button, Header, Skeleton } from '../components';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DataGridCompnent from '../utils/DataGrid';
import { useStateContext } from '../contexts/ContextProvider';
import { useState } from 'react';
import { useEffect } from 'react';
import Api from '../network/proxy';
import { toast } from 'react-toastify';
import Loader from '../utils/loader';
import moment from 'moment';
import { transformGridData } from '../utils/commonFunction';
import { Toolbar } from '@mui/material';
import { FaCloudDownloadAlt } from "react-icons/fa";




function ViewBills() {
    const { currentColor } = useStateContext();
    const [loading, setLoading] = useState(true)
    const [row, setRows] = useState([])
    const gridActions = (params) => (
        <div className="flex items-center gap-1">

            <Toolbar title="Mark as Paid" style={{ padding: 0 }}>
                <Button
                    icon={<DownloadDoneIcon />}
                    color={currentColor}
                    bgHoverColor="light-gray"
                    size="base"
                    borderRadius="50%"
                />
            </Toolbar>
            <Toolbar title="Download Bill" style={{ padding: 0 }}>
                <Button
                    icon={<FaCloudDownloadAlt size={'25px'} />}
                    color={currentColor}
                    bgHoverColor="light-gray"
                    size="base"
                    borderRadius="50%"
                    onClick={() => download(params.row.invoice.content, params.row.invoiceNumber)}
                />
            </Toolbar>
        </div>
    );
    const download = (data, name) => {
        // Your base64-encoded data
        const base64Data = data;

        // Convert base64 to Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${name}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const column = [
        { field: "id", headerName: "Sno.", width: 80 },
        {
            field: 'billingDate',
            headerName: 'Date',
            width: '140',
            textAlign: 'Center',
            renderCell: (params) => moment(params.row.billingDate).format("DD-MMM-YYYY")
        },
        {
            field: 'clientName',
            headerName: 'Client Name',
            width: '240',
            textAlign: 'Center',
        },
        {
            field: 'invoiceNumber',
            headerName: 'Bill Number',
            width: '130',
            textAlign: 'Center'
        },

        {
            field: 'grandTotal',
            headerName: 'Amount',
            width: '110',
            textAlign: 'Center'
        },
        {
            field: 'isPaid',
            headerName: 'Status',
            width: '90',
            textAlign: 'Center',
            renderCell: (params) => params.row.isPaid ? 'Paid' : 'Unpaid'
        },
        {
            field: 'paidOn',
            headerName: 'Paid on',
            width: '120',
            textAlign: 'Center',
            renderCell: (params) => params.row.paidOn ? moment(params.row.paidOn).format("DD-MMM-YYYY") : ''
        },
        {
            field: 'paidAmount',
            headerName: 'Paid Amount',
            width: '130',
            textAlign: 'Center'
        },
        {
            field: 'Actions',
            headerText: 'Actions ',
            width: '190',
            renderCell: (param) => gridActions(param),
        },
    ]

    useEffect(() => {
        async function getInvoices() {
            try {
                setLoading(true)
                const res = await Api.doGet('invoice/all');
                if (res.success === true) {
                    const data = transformGridData(res.data)
                    setRows(data)
                    setLoading(false)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
                if (error.response) {
                    return toast.error(error.response.data.message)
                }
                toast.error("Internal server error")
            }
        }
        getInvoices()
    }, [])
    return (
        <Skeleton>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="View Invoices" />
                <DataGridCompnent columns={column} rows={row} search />
            </div>
            {
                loading && <Loader color={currentColor} />
            }
        </Skeleton>
    );
}

export default ViewBills
